/* body{
    background-color: transparent !important;
} */
.donatio-stream{
    background-color: black;
    width: 400px;
    height: 120px;
    opacity: 0.5;
    border-radius: 8px;
    position: absolute;
    float: right;
    right:0;
    top:0;
  
}
marquee {
    will-change: transform;
    transform: translateZ(0);
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  }
.donation-text{
    font-size: 16px;
    /* font-weight: 600; */
    color: #FFF;
    padding: 0 10px 0 10px;
  
}

.stream-message{
    color: #FFF;
    font-size: 16px;
    padding: 0 10px 10px 10px;
}
.donatio-stream-marquee{
    background-color: black;
    width: 90%;
    height: 50px;
    /* opacity: 0.7; */
    /* border-radius: 8px; */
    position: absolute;
    float: right;
    right:0;
    bottom:2rem;
    flex: 1;
    padding-top: 0.2rem;
}
.virtual-stream-conatiner{
    display: flex;
    align-items: center;
}
.stream-logo {
    background-color: black;
    /* opacity: 0.7; */
    position: absolute;
    float: left;
    left:0;
    bottom:2rem;
    height: 50px;
    width: 10%; 
    margin-right: 20px; 
    padding: 7px 5px 0;
  }

.donation-remaining .hide-remaining{
    display: none !important;
}
.idonated{
    position: absolute;
    float: left;
    left:0;
    background-color: transparent !important;
}

#content {
    width:70px;
    float: right;
}
.thermometer {
    float: right;
    /* margin:0 15px; */
}


#progress-bar {
    width: 100%;
    background-color: #FE5000;
    position: absolute;
    bottom: 0;
  }

  #amount-raised {
    margin-top: -15px;
    font-size: 18px;
    font-weight: 600;
    position: absolute;
    float: right;
    text-align:left;
    right: 20px;
    width: 70px;
    color: #FFF;
  }
  .donation-remaining{
    font-size: 16px;
    text-align: center;
    margin: 0;
    vertical-align: middle;
    color: #FFF;
  }
  .goalamount{
      font-size: 18px;
      color: #FFF;  
      float: right;
      font-weight: 600;
      margin: 30px 15px 0 0;
  }
  .spacing{
    justify-content: space-between;
    padding-left: 20px;
  }
 